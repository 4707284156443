<template>
  <div>
    <ul>
      <li>ข้อ 1 ต้องมากกว่า หรือเท่ากับ ข้อ 2</li>
      <li>ข้อ 2 ต้องเท่ากับ ข้อ 4</li>
      <li>ข้อ 5 + ข้อ 6 + ข้อ 7 ต้องเท่ากับ ข้อ 4</li>
      <li>ข้อ 4 + ข้อ 8 ต้องเท่ากับ ข้อ 3</li>
      <li>ผลรวมของคะแนนทั้งหมด ต้องเท่ากับ ข้อ 5</li>
    </ul>
  </div>
</template>

<script>
export default {
  naem: 'CardKeyInRole'
}
</script>

<style>

</style>