<template>
  <b-container fluid>
    <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="submit()">
      <b-row>
        <b-col>
          <h4 class="mt-3 mb-3">รายงานผลการนับคะแนนเลือกตั้ง นายก อบจ.สุรินทร์</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="5">
          <b-card bg-variant="light" sub-title="1. ข้อมูลหน่วยเลือกตั้ง นายก อบจ.สุรินทร์">
            <div class="table-responsive m-0">
              <table class="table table-bordered table-sm">
                <tbody>
                  <tr>
                    <td width="125px">หน่วยเลือกตั้ง</td>
                    <td>หน่วยเลือกตั้งที่ {{ unit.unit_no }} ต.{{ unit.sub_district_name }}</td>
                  </tr>
                  <tr>
                    <td width="125px">เขตเลือกตั้ง</td>
                    <td>เขตเลือกตั้งที่ {{ unit.area_no }} อ.{{ unit.district_name }} จ.{{ unit.province_name }}</td>
                  </tr>
                  <tr>
                    <td width="125px">ผู้มีสิทธิเลือกตั้ง</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="election_area_base" rules="required" v-slot="{ classes, errors }">
                        <b-form-textarea size="sm" name="election_area_base" :class="classes" v-model="unit.election_area_base" class="width-300" rows="3" max-rows="10"></b-form-textarea>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td width="125px">สถานที่เลือกตั้ง</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="election_place_name" rules="required" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="election_place_name" :class="classes" v-model="unit.election_place_name" class="width-300"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card>
          <b-card bg-variant="light" sub-title="2. ข้อมูลการเลือกตั้ง นายก อบจ.สุรินทร์" class="mt-3">
            <div class="table-responsive m-0">
              <table class="table table-bordered table-sm m-0">
                <tbody>
                  <tr>
                    <td>1. จำนวนผู้มีสิทธิเลือกตั้งในบัญชีรายชื่อผู้มีสิทธิเลือกตั้ง (คน)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="prov_elector" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="prov_elector" :class="classes" v-model="unit.prov_elector" class="width-100"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>2. จำนวนผู้มีสิทธิเลือกตั้งที่มาแสดงตนขอรับบัตรเลือกตั้ง (คน)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="prov_comein" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="prov_comein" :class="classes" v-model="unit.prov_comein" class="width-100"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>3. จำนวนบัตรเลือกตั้งที่ได้รับมาทั้งหมด (บัตร)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="prov_receive" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="prov_receive" :class="classes" v-model="unit.prov_receive" class="width-100"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>4. จำนวนบัตรเลือกตั้งที่ใช้ลงคะแนนเลือกตั้ง (บัตร)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="prov_use" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="prov_use" :class="classes" v-model="unit.prov_use" class="width-100"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>5. จำนวนบัตรดี (บัตร)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="prov_good" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="prov_good" :class="classes" v-model="unit.prov_good" class="width-100"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>6. จำนวนบัตรเสีย (บัตร)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="prov_fail" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="prov_fail" :class="classes" v-model="unit.prov_fail" class="width-100"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>7. จำนวนบัตรเลือกตั้งที่ทำเครื่องหมายไม่เลือกผู้สมัครใด (บัตร)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="prov_no_vote" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="prov_no_vote" :class="classes" v-model="unit.prov_no_vote" class="width-100"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>8. จำนวนบัตรเลือกตั้งที่เหลือ (บัตร)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="prov_remain" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="prov_remain" :class="classes" v-model="unit.prov_remain" class="width-100"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="p-0 pt-2">
                      <span class="ps-1"> 9. ผู้สมัครรับเลือกตั้งได้คะแนนเรียงตามลำดับหมายเลขประจำตัวผู้สมัคร ดังนี้ </span>
                      <b-container fluid class="p-0 mt-2">
                        <div class="table-responsive mb-0 mt-10">
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th class="text-center" width="1%">หมายเลข</th>
                                <th class="text-center" width="1%"></th>
                                <th class="text-center">ชื่อตัว ชื่อสกุล</th>
                                <th class="text-center" width="70px">คะแนน</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="applicant in applicant_provs" :key="applicant.id">
                                <td class="text-center p-0" width="1%">
                                  <span class="fs-40px fw-bold p-0">{{ applicant.number }}</span>
                                </td>
                                <td width="1%" class="with-img p-0">
                                  <img :src="applicant.img_path" class="img-rounded width-65" />
                                </td>
                                <td class="pt-3">
                                  <h5>{{ applicant.prefix_name + applicant.first_name + ' ' + applicant.last_name }}</h5>
                                </td>
                                <td class="form-inline p-1 pt-3" width="1%">
                                  <ValidationProvider :name="'prov_score_' + applicant.number" rules="required|numeric" v-slot="{ classes, errors }">
                                    <b-form-input size="lg" :name="'prov_score_' + applicant.number" :class="classes" v-model="unit['prov_score_' + applicant.number]" class="width-100"></b-form-input>
                                    <span class="invalid-feedback">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card>
          <b-alert show variant="danger" class="mt-3">
            <h4>
              หมายเหตุ
              <small>กฏสำหรับการป้อนข้อมูล</small>
            </h4>
            <CardKeyInRole />
          </b-alert>
        </b-col>
        <b-col>
          <b-card bg-variant="light" sub-title="3.1 แบบรายงานผลคะแนนเลือกตั้ง ฯ (ส.ถ. ๕/๗) หน้า 1">
            <b-form-file accept="image/jpeg, image/png, image/jpg" id="my-file-1" @change="previewImage1" plain></b-form-file>
            <div class="border p-2 mt-3">
              <p>แบบรายงานผลคะแนนเลือกตั้ง ฯ ส.ถ. ๕/๗ หน้าที่ 1:</p>
              <template v-if="preview1">
                <b-img thumbnail fluid :src="preview1" class="img-rounded" />
              </template>
            </div>
          </b-card>
          <b-card bg-variant="light" sub-title="3.2 แบบรายงานผลคะแนนเลือกตั้ง ฯ (ส.ถ. ๕/๗) หน้า 2" class="mt-3">
            <b-form-file accept="image/jpeg, image/png, image/jpg" id="my-file-2" @change="previewImage2" plain></b-form-file>
            <div class="border p-2 mt-3">
              <p>แบบรายงานผลคะแนนเลือกตั้ง ฯ ส.ถ. ๕/๗ หน้าที่ 2:</p>
              <template v-if="preview2">
                <b-img thumbnail fluid :src="preview2" class="img-rounded" />
              </template>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr />
          <div class="col-12 text-end">
            <b-button variant="danger" size="lg" class="ms-2" @click="hideModal()">
              <b-icon icon="x-square" aria-hidden="true"></b-icon>
              &nbsp; ยกเลิก
            </b-button>
            <b-button type="submit" variant="success" size="lg" :disabled="invalid" class="ms-2">
              <b-icon icon="check2-square" aria-hidden="true"></b-icon>
              &nbsp; {{ currentUser.group_id == 3 ? 'บันทึกการส่งผลคะแนน' : 'บันทึกการยืนยันผลคะแนน' }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-container>
</template>

<script>
import CardKeyInRole from '../share/CardKeyInRole.vue'

export default {
  name: 'PresidentFormConfirm',
  components: {
    CardKeyInRole,
  },
  data() {
    return {
      election_unit_id: this.$props.id,
      unit: {},
      applicant_provs: [],
      preview1: null,
      image1: null,
      preview2: null,
      image2: null,
    }
  },
  props: {
    id: null,
  },
  mounted() {
    this.getData()
  },
  computed: {
    currentUser() {
      let user = this.$store.state.auth.user
      // user.group_id = 3
      // user.district_id = 3201
      // user.election_area_id = 1
      return user
    },
  },
  methods: {
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'edit-modal')
    },
    previewImage1(e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        this.preview1 = e.target.result
      }
      reader.onloadend = () => {
        this.image1 = reader.result
      }
      reader.readAsDataURL(file)
    },
    previewImage2(e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        this.preview2 = e.target.result
      }
      reader.onloadend = () => {
        this.image2 = reader.result
      }
      reader.readAsDataURL(file)
    },
    getData() {
      this.axios.get('election_units/' + this.election_unit_id).then((res) => {
        this.unit = res.data
        if (res.data.image1) {
          this.preview1 = res.data.image1
        } else {
          this.preview1 = null
        }
        if (res.data.image2) {
          this.preview2 = res.data.image2
        } else {
          this.preview2 = null
        }
        let param = {
          t: 'get_applicants',
        }
        this.axios.get('applicant_provinces', { params: param }).then((res) => {
          this.applicant_provs = res.data
        })
      })
    },
    submit() {
      if (parseInt(this.unit.prov_elector) < parseInt(this.unit.prov_comein)) {
        this.$toast.error('ข้อ 1 ต้องมากกว่า หรือ เท่ากับ ข้อ 2 กรุณาตรวจสอบ!')
      } else if (parseInt(this.unit.prov_comein) != parseInt(this.unit.prov_use)) {
        this.$toast.error('ข้อ 2 ไม่เท่ากับ ข้อ 4 กรุณาตรวจสอบ!')
      } else if (parseInt(this.unit.prov_good) + parseInt(this.unit.prov_fail) + parseInt(this.unit.prov_no_vote) != parseInt(this.unit.prov_use)) {
        this.$toast.error('ข้อ 5 + ข้อ 6 + ข้อ 7 ไม่เท่ากับ ข้อ 4 กรุณาตรวจสอบ!')
      } else if (parseInt(this.unit.prov_use) + parseInt(this.unit.prov_remain) != parseInt(this.unit.prov_receive)) {
        this.$toast.error('ข้อ 4 + ข้อ 8 ไม่เท่ากับ ข้อ 3 กรุณาตรวจสอบ!')
      } else if (parseInt(this.unit.prov_score_1) + parseInt(this.unit.prov_score_2) + parseInt(this.unit.prov_score_3) + parseInt(this.unit.prov_score_4) + parseInt(this.unit.prov_score_5) != parseInt(this.unit.prov_good)) {
        this.$toast.error('ผลรวมของคะแนนทั้งหมด ไม่เท่ากับ ข้อ 5 กรุณาตรวจสอบ!')
      } else {
        this.$refs.observer.validate().then((result) => {
          if (result) {
            let loader = this.$loading.show()
            if (this.currentUser.group_id == 3) {
              this.unit.prov_confirm = 1
            } else {
              this.unit.prov_confirm = 2
            }
            if (this.image1) {
              this.unit.image1 = this.image1
            }
            if (this.image2) {
              this.unit.image2 = this.image2
            }
            this.axios
              .put(`election_units/${this.unit.id}`, this.unit)
              .then((response) => {
                this.$nextTick(() => {
                  loader.hide()
                  if (response.data.error) {
                    this.$toast.error(response.data.message)
                  } else {
                    if (this.currentUser.group_id == 3) {
                      if (this.unit.prov_confirm == 0) {
                        this.$toast.warning('ระบบได้ทำการยกเลิกการส่งผลการนับคะแนนเรียบร้อยแล้ว')
                      } else {
                        this.$toast.success('ระบบได้ทำการบันทึกและส่งผลการนับคะแนนเรียบร้อยแล้ว')
                      }
                    } else {
                      if (this.unit.prov_confirm == 1) {
                        this.$toast.warning('ระบบได้ทำการยกเลิกการยืนยันผลการนับคะแนนเรียบร้อยแล้ว')
                      } else {
                        this.$toast.success('ระบบได้ทำการบันทึกยืนยันผลการนับคะแนนเรียบร้อยแล้ว')
                      }
                    }
                    this.$emit('update-data', response.data)
                  }
                })
              })
              .catch((error) => {
                this.$nextTick(() => {
                  loader.hide()
                  console.log(error)
                  this.$toast.error(error.response.data.message)
                })
              })
          } else {
            this.$toast.error('ข้อมูลไม่ครบถ้วนถูกต้องสมบูรณ์ กรุณาแก้ไขข้อมูลให้ถูกต้อง!')
          }
        })
      }
    },
  },
}
</script>

<style>
.height-40 {
  height: 40px !important;
}
.width-45 {
  width: 45px !important;
}
.width-65 {
  width: 65px !important;
}
.width-100 {
  width: 100px !important;
}
.width-150 {
  width: 150px !important;
}
.width-200 {
  width: 200px !important;
}
.width-300 {
  width: 300px !important;
}
.width-350 {
  width: 350px !important;
}
.width-450 {
  width: 450px !important;
}
</style>
